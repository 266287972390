









import { Vue, Component } from "vue-property-decorator";
import ManagementNavigationView from "./ManagementNavigationView.vue";
import store from "@/main-store";

@Component({
    components: { ManagementNavigationView },
    data: () => ({
        name: "management",
        user: null
    })
})
export default class ManagementView extends Vue {
	public get hidden() {
		const path = this.$route.fullPath;
		if (path.startsWith("/management/customers/navigator")) {
			return true;
		}
		return false;
	}

	public async created() {
        store.events.subscribe('user', this.userListener);
        await store.loadUser();
    }

    public destroyed() {
        store.events.remove('user', this.userListener);
    }

    public userListener(user: any) {
        this.$data.user = user;
    }

    public get navigationLeft() {
        if (!this.$data.user || this.hidden) {
            return "";
        } else if (this.$vuetify.breakpoint.mdAndDown) {
            return "margin-left:56px;";
        } else {
            return "margin-left:256px;";
        }
    }


}
