














































































































import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class ManagementNavigationView extends Vue {
    @Prop({ default: true }) private visible!: Boolean;
    @Prop({ default: false }) private alwaysExpandOnHover!: Boolean;
    @Prop({ default: false }) private alwaysMiniVariant!: Boolean;
    private footer: HTMLElement;
    private navigationDrawer: HTMLElement;

    public get miniVariant() {
        return this.alwaysMiniVariant || this.$vuetify.breakpoint.mdAndDown;
    }

    public get expandOnHover() {
        return this.alwaysExpandOnHover || this.$vuetify.breakpoint.mdAndDown;
    }

    public created() {
        window.addEventListener("scroll", this.adjustPaddingBottom);
        window.addEventListener("resize", this.adjustPaddingBottom);
    }

    public mounted() {
        this.navigationDrawer = document.getElementById("navigation-drawer");
        this.footer = document.getElementById("footer");
    }

    public destroyed() {
        window.removeEventListener("scroll", this.adjustPaddingBottom);
        window.removeEventListener("resize", this.adjustPaddingBottom);
    }

    public adjustPaddingBottom(e) {
        const footerHeight = this.footer.clientHeight;
        const bodyHeight = document.body.clientHeight;
        const innerHeight = window.innerHeight;
        const scrollTop = document.body.scrollTop;
        var paddingBottom = 64 - (bodyHeight - innerHeight) + scrollTop;

        if (paddingBottom < 64 + footerHeight) {
            paddingBottom = paddingBottom + 58;
        }
        if (paddingBottom < 64) {
            paddingBottom = 64;
        }
        this.navigationDrawer.style.paddingBottom = paddingBottom + "px";
    }
}
